// Entry point for the browser
// Start your React application and add the required containers
// Here we have <BrowserRouter /> for react-router

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';


const element = document.createElement("div");
document.body.appendChild(element);
const MainApp = (
  // <BrowserRouter>
    <App />
  // </BrowserRouter>
);
// console.log('renderelemnet app', app);
ReactDOM.render(<App />, element);
