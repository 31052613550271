import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainLayout from '~layouts/MainLayout'

export default function About() {
  return (
    <MainLayout>
      <Helmet>
        <title>About nico</title>
      </Helmet>
      <h1 style={{ textAlign: 'center' }} className="hello-world">About me</h1>
      <p style={{ textAlign: 'center' }} className="hello-world">You will know more, soon...</p>
    </MainLayout>);
}
