import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainLayout from '~layouts/MainLayout'

export default function Home() {
  return (
      <MainLayout>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <h1 style={{ textAlign: 'center' }} className="hello-world">Welcome home.</h1>
        <p style={{ textAlign: 'center' }}>
          This is home
        </p>
      </MainLayout>
    );
}
