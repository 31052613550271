import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '~layouts/MainLayout'
import { Link } from 'react-router-dom';
import MainMenu from '~components/mainMenu/MainMenu';

export default function Blog() {
  return (
    <MainLayout>
      <Helmet>
        <title>Bayet.io - Articles</title>
      </Helmet>
      <h1 style={{ textAlign: 'center' }} className="hello-world">Articles</h1>
      <p style={{ textAlign: 'center' }} className="hello-world">Coming soon...</p>
    </MainLayout>);
}
