// Main component of our application.
// We setup react-helmet, which let us nicely manage our <head />
// It's a nice library you should use!

import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from '~/pages/home/Home.tsx';
import Articles from '~pages/article/Articles';
import About from '~/pages/about/About.tsx';

export default function App() {
  return (
    <BrowserRouter>
      <div>
        <Helmet defaultTitle="Hello World!">
          {/* test with charset instead of charSet */}
          <meta charSet="utf-8" />
        </Helmet>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/articles" component={Articles} />
          <Route exact path="/about" component={About} />
          <Redirect to="/" />
        </Switch>
      </div>
    </BrowserRouter>
  );
}
