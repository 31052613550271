import React from 'react';
import { Helmet } from 'react-helmet';
import MainMenu from '~components/mainMenu/MainMenu';
import "./MainLayout.styl"

export default class MainLayout extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Bayet.io</title>
          {/* todo: metadata - seo */}
          {/* todo: metadata - opengraph */}
          {/* todo: metadata - icons */}
          {/* todo: see https://devhints.io/html-meta */}
          <link href="https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet"/>
        </Helmet>
        <header>
          <div className="website-title">Nicolas Bayet</div>
          <MainMenu/>
          <div className="header-search"/>
        </header>
        <div className="wrapper">
          {this.props.children}
        </div>
        <footer>
        </footer>
      </div>);
  }
}
